@import "variables";
@import "mixins";
@import "fonts";

body{
  font-family: Roboto, Helvetica, sans-serif !important;
}

.fake-content{
  font-size: 1px;
  width: 0;
  height: 0;

  .fw-300{
    font-weight: 300;
  }
  .fw-400{
    font-weight: 400;
  }
  .fw-500{
    font-weight: 500;
  }
  .fw-600{
    font-weight: 600;
  }
  .fw-700{
    font-weight: 700;
  }
  .fw-800{
    font-weight: 800;
  }
  .fw-900{
    font-weight: 900;
  }
}

.cw-container{
  &.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    min-height: calc(100vh - 52px) !important;

    &.mobile{
      &.landscape{
        min-height:calc(var(--windowInnerHeight) - 28px) !important;
      }
      &.portrait{
        min-height:calc(var(--windowInnerHeight) - 28px) !important;
      }
    }

    &.tablet{
      min-height:calc(var(--windowInnerHeight) - 28px) !important;
    }

    .search-container{
      &.browser{
        width: 650px;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;

        .unhighlight{
          font-weight: bold;
        }

        .console-container{
          display: flex;
          width: 100%;

          @include search-console('browser',650px,558px);
        }
      }

      &.mobile{
        width: 100%;
        height: calc(var(--windowInnerHeight) - 172px);
        position: relative !important;

        .img-logo{
          position: absolute;
          top: 134px;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          transition: top 200ms ease-out;
        }

        .unhighlight{
          font-weight: bold;
        }

        .console-container{
          position: absolute;
          top: 197px;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
          display: flex;
          width: 80%;
          transition: all 200ms ease-out;
          z-index: 2;

          @include search-console('mobile',100%,70%);
        }

        p{
          position: absolute;
          top: 270px;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        .link{
          position: absolute;
          top: 315px;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }

        .search-backdrop{
          width: 100% !important;
          height: var(--windowInnerHeight) !important;
          background-color: rgba(0,0,0,.6) !important;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
        }
      }
    }
  }

  &.search{
    display: flex;
    flex-direction: column;

    .unhighlight{
      font-weight: bold;
    }

    &.mobile{
      background-color: #f5f5f5;

      @include header('mobile','',0,48px,85%);
      @include tabContainer('mobile');

      &.landscape{
        @include mainContent('mobile','landscape');
      }
      &.portrait{
        @include mainContent('mobile','portrait');
      }
    }

    &.tablet{
      &.landscape{
        @include header('tablet','landscape',16px,507px,415px);
        @include leftSideBlank('tablet');
      }

      &.portrait{
        @include header('tablet','portrait',16px,72%,75%);
        @include leftSideBlank('tablet');
      }

      @include tabContainer('tablet');
      @include mainContent('tablet','');
    }

    &.browser{
      @include header('browser','',24px,650px,558px);
      @include tabContainer('browser');
      @include leftSideBlank('browser');
      @include mainContent('browser','');
    }

    .no-results{
      padding-top: 50px;
      padding-left: 20px;
      color: #666;
      flex: 0 0 100%;

      h1{
        font-size: 18px;
        line-height: 22px;
        font-weight: normal !important;
        margin-bottom: 0 !important;
        padding-bottom: 5px;
      }

      span{
        line-height: 1.2em !important;
        font-size: 13px !important;
      }
    }

    .related-search-container{
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      color: $text-color-primary;

      &.mobile{
        @include relatedSearch('mobile','');
      }

      &.tablet{
        &.landscape{
          @include relatedSearch('tablet','landscape');
        }

        &.portrait{
          @include relatedSearch('tablet','portrait');
        }
      }

      &.browser{
        @include relatedSearch('browser','');
      }
    }

    .query-analysis-container{
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      color: $text-color-primary;
      padding: 0 0 0 20px;
      margin: 15px 0 0 40px;
      flex: 0 0 530px;

      .title{
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
      }

      .search-text{
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: #000;
        padding-left: 5px;
      }

      .query-rows-container{
        margin-top: 34px;

        .similar-text{
          color: rgba(0, 0, 0, 0.87) !important;
          text-decoration: none !important;

          &:hover{
            color: #1a0dab !important;
            text-decoration: underline !important;
          }
        }

        @include slimScrollbarMDTable();
      }

      .query-domain-container{
        margin-top: 34px;

        @include slimScrollbarMDTable();
      }
    }

    .search-list-container{
      display: flex;
      flex-direction: column;

      &.browser{
        padding-top: 40px;
        width: 648px !important;
        @include itemsContainer('browser','');
      }

      &.tablet{
        padding-top: 40px;

        &.landscape{
          width: 557px !important;
          @include itemsContainer('tablet','landscape');
        }

        &.portrait{
          width: 648px !important;
          @include itemsContainer('tablet','portrait');
        }
      }

      &.mobile{
        flex: 1;

        @include itemsContainer('mobile','');

        .float-icon{
          position: fixed;
          bottom: 45px;
          right: 0;
          z-index: 1000;
          padding: 20px;

          .btn-search{
            border: none;
            outline: none;
            width: 56px;
            height: 56px;
            border-radius: 50%;
            box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 4px 12px 1px rgba(0,0,0,.2);
            -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,.08), 0 4px 12px 1px rgba(0,0,0,.2);
            background-color: #fff;
          }
        }

        .animate-enter {
          right: -100px;
          opacity: 0;
          transition: right 500ms ease, opacity 500ms ease;
        }

        .animate-enter-active {
          right: 0;
          opacity: 1;
          transition: right 500ms ease, opacity 500ms ease;
        }

        .animate-exit {
          right: 0;
          opacity: 1;
          transition: right 500ms ease, opacity 500ms ease;
        }

        .animate-exit-active {
          right: -100px;
          opacity: 0;
          transition: right 500ms ease, opacity 500ms ease;
        }
      }

      .result-info{
        font-size: 12px;
        font-weight: 400;
        color: $text-color-primary;
        height: 30px;
        margin-top: -28px;
        line-height: 30px;
        padding-left: 20px;
      }

      .bottom-blank{
        height: 20px;
      }
    }
  }

  &.settings{
    display: flex;
    flex-direction: column;

    .unhighlight{
      font-weight: bold;
    }

    &.mobile{
      background-color: #f5f5f5;
      @include header('mobile','',0,48px,85%);

      .page-title{
        padding-left: 9px;
      }
    }

    &.tablet{
      &.landscape{
        @include header('tablet','landscape',16px,507px,415px);
        .page-title{
          padding-left: 48px;
        }
      }

      &.portrait{
        @include header('tablet','portrait',16px,72%,75%);
      }
    }

    &.browser{
      @include header('browser','',14px,547px,453px);
    }

    .page-title-blank{
      height: 9px;
      border-bottom: 1px #ececec solid;
    }

    .page-title{
      height: 43px;
      line-height: 42px;
      border-bottom: 1px #ececec solid;
      padding-left: 60px;

      h2{
        font-size: 18px;
        color: $text-color-primary-dark;
        font-weight: 400;
        margin-top: 10px;
      }
    }

    .content{
      min-height: calc(100vh - 162px) !important;
      background-color: #eee;

      .row-top-blank{
        height: 30px;
      }

      .left-menu{
        .nav-item{
          background-color: #fff;

          .nav-link{
            font-size: 14px;
            font-weight: bold;
            text-transform: uppercase;
            padding: 14px;
            background-color: #fff;
            display: block;
            width: 180px;
            color: $text-color-primary !important;
          }
        }
      }

      .settings{
        h2{
          font-weight: bold;
          font-size: 14px;
          text-transform: uppercase;
          padding: 0;
          line-height: 1.2em;
          color: $text-color-primary-dark;
        }

        .search{
          background-color: #fff;

          h3{
            font-size: 18px;
            color: #000;
            line-height: 22px;
            font-weight: normal;
          }

          select{
            border: 1px solid #bbb !important;
            max-width: 160px;

            &:focus{
              box-shadow: none !important;
            }
          }
        }

        .save{
          margin-top: 35px;
          background-color: #fff;
        }
      }
    }
  }

  &.image-zoom{
    display: flex;
    flex-direction: column;

    @include header('browser','',0,650px,558px);
  }
}

/******** Image Gallery: Start *******/
.image-gallery-container{
  flex: 100% 0 0;
  box-sizing: border-box;
  margin-left: 15px;
  margin-right: 15px;

  .image-gallary-row{
    margin-bottom:45px;

    &:before{
      content: "";
      display: table;
    }

    /* clearfix because of floats */
    &:after{
      content: "";
      display: table;
      clear: both;
    }

    .row{
      margin:0 !important;
    }

    .image-gallery-item {
      position:relative;
      float: left;
      margin-top:5px;
      margin-bottom: 5px;

      /* clearfix because of floats */
      &.first-item {
        clear: both;
      }

      img {
        width: 100%;
        height: 100%;
      }

      .image-footer{
        position: absolute;
        display: block;
        left:0;
        right:0;
        bottom: 0;
        color: white;
        background: rgba(51,51,51,0.8);
        padding: 1px 2px;
        font-size: 11px;
      }
    }
  }
}
/******** Image Gallery: End *******/

/******** New Image Gallery : Start *******/
.container-image-gallery{
  margin-top: 5px;

  &.browser{
    min-width:calc(100vw - 60px);
    min-height:calc(100vh - 178px);
    margin-left: 20px !important;
    margin-right: 20px !important;

    .left-side-images{
      width: 100px !important;
      height: 200px !important;
    }
  }

  &.mobile{
    min-width:calc(100vw - 20px);
    min-height:calc(var(--windowInnerHeight) - 202px);
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  /* clearfix because of floats */
  &:before, &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }

  .image-gallery-item {
    float: left;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    a{
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      vertical-align: bottom;
    }
  }

  .first-item {
    clear: both;
  }
  /* remove margin bottom on last row */
  .last-row, .last-row ~ .item {
    margin-bottom: 0;
  }
}
/******** New Image Gallery: End *******/

.pagination-container{
  &.mobile{
    @include paginationContainer('mobile');
  }

  @include paginationContainer('browser');

  .pagination{
    margin-bottom: 0;

    .previous-item{
      width: 40px;
      height: 45px;
      margin-top: 0;
      margin-right: 20px;
      line-height: 42px;
      overflow: hidden;

      img{
        transform: scale(.5) rotate(180deg);
      }

      a:hover{
        background-color: #f4f4f4 !important;
        cursor: pointer !important
      }

      &.disabled {
        display: none;
      }
    }

    .next-item{
      width: 40px;
      height: 45px;
      margin-top: 0;
      margin-right: 20px;
      overflow: hidden;

      img{
        transform: scale(.5);
        transform-origin: -1418px -10px;
        overflow: hidden;
      }

      a:hover{
        background-color: #f4f4f4 !important;
        cursor: pointer !important
      }

      &.disabled {
        display: none;
      }
    }

    .page-item {
      display: flex;
      justify-content: center;

      &.active {
        a.page-link {
          color: #111 !important;
          background-color: #fff !important;

          &:hover{
            color: $text-color-primary !important;
            background-color: #fff !important;
            cursor: text !important;
          }
        }
      }

      a.page-link {
        width: 40px;
        height: 45px;
        color: $text-color-primary !important;
        font-size: 13px;
        padding: 0 !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        margin-left: 0 !important;

        &:focus {
          box-shadow: none !important;
        }

        &:hover{
          background-color: #f4f4f4 !important;
        }
      }
    }

    a.page-link{
      cursor: pointer !important;
      border: none;
    }
  }
}

.cw-footer-container{
  background-color: #f5f5f5 !important;

  &.fixed{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
  }

  &.absolute{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.mobile{
    padding: 2px 0;
    justify-content: center;

    .nav-link {
      padding: 0 !important;
      margin-right: 10px;
      margin-left: 10px;
      height: 24px !important;
      line-height: 24px;
    }
  }

  &.browser{
    padding: 12px 0;
    justify-content: flex-start;

    .nav-link {
      padding: 0 !important;
      margin-right: 48px;
      height: 24px !important;
      line-height: 24px;

      &:first-child{
        margin-left: 122px;
      }
    }
  }
}

// ******** Image loader *******
i.bubble-loader{
  display: block;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  z-index: 10000 !important;

  span{
    display: inline-block;
    background-color: darken(#777777, 10%);
    width: 1rem;
    height: 1rem;
    margin-left: .2rem;
    margin-right: .2rem;
    opacity: .4;
    border-radius: 50%;
    @for $i from 1 through 3{
      &:nth-child(#{$i}){
        -webkit-animation: 1s blink infinite ($i * .3333s);
        -moz-animation: 1s blink infinite ($i * .3333s);
        animation: 1s blink infinite ($i * .3333s);
      }
    }
  }
}

@-webkit-keyframes blink{
  50%{
    opacity: 1;
  }
}
@-moz-keyframes blink{
  50%{
    opacity: 1;
  }
}
@keyframes blink{
  50%{
    opacity: 1;
  }
}
// ******** Image loader: End *******

.search-console-mobile{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  background-color: #fff;
  overflow: auto;

  .search-container{
    display: flex;
    align-items: center;
    margin: 5px 0;

    .unhighlight{
      font-weight: bold;
    }

    .arrow-left{
      flex: 0 0 50px;
    }

    .input-container{
      flex: 1 1;
      padding: 7px 0 6px;

      .input-search{
        width: 100%;
        border:none;
        outline: none;

        &:focus{
          outline: none !important;
        }
      }
    }

    .btn-clear{
      flex: 0 0 24px;
    }

    .btn-search{
      z-index: 100000;
      background-color: transparent;
      border: none;
      outline: none;
      flex: 0 0 50px;
    }

    .icon-arrow{
      flex: 0 0 24px;

      img{
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
        margin: 0 11px 0 0;
        padding: 10px;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAQAAADZc7J/AAAAiklEQVR42u3VXwqAIAwH4F3PN988V+6IXiMowsQ0/7SfEAXOV/eJY1OiTwQr65atvqxj1QTa6Z5oAr30YaB7hWTzykZcxOw8ORFTQSIAbEAiAEQgEQGQuAIQkQIAkQNi4g4IiRIgIsqAgPDjXJq4h8TxoNQmDu7OSbxB6FHCoZ/QScAAEet62/0ydu8pkODNGcg5AAAAAElFTkSuQmCC)
      }
    }
  }

  hr{
    margin: 0 10px;
  }
}

.image-detail-mobile{
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(var(--windowInnerHeight) - 28px) !important;

  .cross{
    position: absolute;
    top:10px;
    left:10px;
    background-color: rgba(0,0,0,0.302);
    border-radius: 50%;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
  }

  .image{
    width: 100vw;
    position: absolute;
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;

    &.thumbnail{
      z-index: 900 !important;
    }

    &.original{
      z-index: 1000 !important;
    }
  }

  .details{
    display: flex;
    justify-content: space-between;
    padding: 12px 16px;

    .left{
      display: flex;
      flex-direction: column;

      .domain{
        display: flex;
        align-items: center;

        .favicon{
          width: 18px;
          height: 18px;
          margin-right: 5px;
        }

        .text{
          color: #5f6368;
          margin-top: 3px;
        }
      }

      .description{
        color: #202124;
        letter-spacing: .01785714em;
        font-size: .875rem;
        font-weight: 400;
        line-height: 1.25rem;
        overflow: hidden;
        padding-top: 6px;
      }
    }

    a{
      border: none;
      text-align: center;
      align-items: center;
      border-radius: 36px;
      display: inline-flex;
      font-size: 14px;
      height: 36px;
      max-width: 100%;
      padding: 8px 30px;
      font-weight: 400;
      letter-spacing: .01785714em;
      line-height: 1.25rem;
      color: #fff !important;
      margin-top: 4px;
      margin-left: 8px;

      &:hover{
        text-decoration: none !important;
      }
    }
  }
}

.swiper-container{
  min-height: calc(var(--windowInnerHeight) - 28px) !important;
}

.modal{
  padding-left: 0 !important;
  z-index: 100000;

  .image-detail-modal{
    width: 100vw !important;
    max-width: none !important;
    margin: 0 !important;

    .modal-content{
      width: 100vw !important;
      height: 100vh !important;
      border: none !important;
      border-radius: 0 !important;

      .modal-header{
        padding: 0 !important;
        height: 97px !important;
        align-items: center !important;
        background-color: #222 !important;

        .close-icon{
          margin-right: 22px !important;
          color: #fff !important;
          width: 28px !important;
          height: 28px !important;
        }
      }

      .modal-body{
        padding: 0 !important;

        .description{
          color: black;
          position: absolute;
          top: -1px;
          right: 0;
          width: 20%;
          height: calc(100vh - 184px);
          background-color: #2b2b2b;
          border-left: 1px solid #3e3e3e;
          padding: 16px 20px;

          .card{
            display: flex;
            flex-direction: column;
            padding: 12px;
            background-color: #333;
            -moz-box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
            -webkit-box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
            box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);

            .domain{
              opacity: .8;
              color: #fff !important;
              font-size: 11px;
              line-height: 16px;
              text-decoration: none;
            }

            .title{
              display: inline;
              font-size: 14px;
              height: 20px;
              line-height: 20px;
              max-height: 40px;
              margin-right: 0;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
              color: #fff !important;
              font-weight: 700;
              text-decoration: none;
            }

            .favicon{
              width: 12px;
              height: 12px;
              object-fit: cover;
              margin-right: 8px;
            }

            .divider{
              line-height: 16px;
              color: #fff;
              margin-left: 8px;
              margin-right: 8px;
            }

            .pixel{
              color: #fff !important;
              font-size: 11px;
              line-height: 16px;
              text-decoration: none;
              cursor: default;
            }
          }
        }
      }
    }
  }
}

.image-gallery-content{
  position: fixed;
  line-height: 0;
  top: 96px !important;
  left: 0;
  right: 20%;
  bottom: 88px;
  background-color: #2b2b2b;

  .image-gallery-svg{
    width: 42px !important;
    height: 42px !important;
  }

  .image-gallery-slide-wrapper{
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .image-gallery-fullscreen-button{
    position: absolute;
    top: -79px !important;
    right: 60px !important;

    //.zmin-icon{
    //  width: 20px !important;
    //  height: 20px !important;
    //}
  }

  .image-gallery-slide{
    &:focus{
      outline: none !important;
    }

    div:focus{
      outline: none !important;
    }

    .image-gallery-image {
      width: auto !important;
      max-height: calc(100vh - 184px);

      &:focus{
        outline: none !important;
      }
    }
  }

  .image-gallery-thumbnails-wrapper{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #222;
    height: 88px;
    align-items: center;
    display: flex;
    justify-content: center;

    .image-gallery-thumbnails{
      flex: 0 0 60%;
    }

    .image-gallery-thumbnail{
      width: 60px !important;
      margin-right: 4px !important;
      outline: none !important;
      border: none !important;

      &.active{
        border: none !important;

        .image-gallery-thumbnail-inner{
          opacity: 1;
          border-bottom: 3px solid #fff !important;
        }
      }

      &:hover{
        border: none !important;
      }

      .image-gallery-thumbnail-inner{
        width: 60px !important;
        opacity: .3;
        transition: opacity .5s;

        &:hover{
          opacity: 1 !important;
        }

        img{
          width: 60px !important;
          height: 60px !important;
          object-fit: cover !important;
        }
      }
    }
  }
}

.unhighlight{
  font-weight: bold;
}
