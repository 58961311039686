.form-control{
  border-radius: 0 !important;
  border: 0 !important;

  &:focus{
    border-color: #ced4da !important;
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08) !important;
  }
}

.btn{
  border-radius: 0 !important;

  &:active{
    box-shadow:none;
  }
}

a{
  color: #1a0dab !important;
}

.search-title{
  color: #212529;
  font-size: 18px;
}

.search-title:hover{
  color: #007bff;
}

.did-you-mean{
  .showing-results{
    font-size: 1.1rem !important;
  }
}

#toast-container.toast-top-right {
  top: 80px;
  right: 3px;
}

.btn.btn-primary{
  color : #FFF;

  &:focus{
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08) !important;
  }
}

.nav-link-privacy, .nav-link-terms{
  color: #666 !important;
  font-size: 13px;
}

.nav-link-privacy, .nav-link-terms{
  &:hover{
    text-decoration: underline !important;
  }
}

.search-description{
  font-size: small;
  line-height:1.4;
  color: #545454 !important;

  b{
    color: #6a6a6a !important;
  }
}

.ulimited-free-api{
  font-size: 14px;
}

.main-logo{
  width: 100%;
  height: auto;
}

.custom-nav-tab{
  li{
    font-size:.8rem;

    &.active{
      border-bottom: 3px solid #4285f4;
      font-weight:bold;
    }

    a{
      color:#777 !important;

      &.active{
        color: #4285f4 !important;
      }
    }
  }
}

.no-results-row{
  margin-top: 70px;
  padding-left: 10px;

  ul{
    margin-top:5px
  }
}

.btn-input-cross{
  position: absolute;
  top: 0;
  right: 39px;
  z-index: 100000;
  background: transparent;

  &:focus{
    outline:0 !important;
    box-shadow: none !important;
  }
}

.search-input{
  padding-right: 36px !important;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);

  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08);
  }
}

.search-icon-box{
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
  transition: box-shadow 200ms cubic-bezier(0.4, 0.0, 0.2, 1);

  &:hover{
    box-shadow: 0 3px 8px 0 rgba(0,0,0,0.2), 0 0 0 1px rgba(0,0,0,0.08);
  }
}

.pagination-column{
  min-width:650px;

  .pagination{
    padding-top: 30px !important;

    .page-item {
      &.disabled {
        a.page-link {
          color: #cacaca !important;
        }
      }

      &.active {
        a.page-link {
          color: #fff !important;
        }
      }

      a.page-link {
        &:focus {
          box-shadow: none !important;
        }
      }
    }

    a.page-link{
      cursor: pointer !important;
      border: none !important;
    }
  }
}

.news-item-row{
  .search-title{
    font-size:15px !important;
    letter-spacing: -.02rem;
    word-spacing: -1px;
  }

  .title-column{
    padding-left:19px;
  }

  .publishtime-column{
    padding-left:19px;
  }

  .search-description{
    line-height: 1.24 !important;
    word-wrap: break-word;
    font-size: 13px;
    border-collapse: collapse;
    font-family: arial,sans-serif;
    text-align: left;
    font-weight: normal;
    padding-left:19px;
  }
}

.all-item-row{
  .with-image-column{
    .search-title-column{
      margin-top: -26px;
      margin-left: 35px;
    }

    .search-title{
      padding-left:17px;
    }

    .search-uri{
      padding-left:21px;
    }

    .search-description{
      padding-left:36px;
    }
  }

  .search-title-column{
    margin-left: 14px;
  }

  .time-ago{
    color: #808080;
  }
}


/* Boostrap Breakpoints. START */
// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {
  .main-logo{
    width:97%;
    margin-bottom:10px;
  }

  .pagination-column{
    padding-left: 0 !important;
    padding-right: 0 !important;
    min-width:100% !important;

    .pagination{
      padding-left:0;
      font-size:13px;
    }
  }

  .news-item{
    .search-title{
      font-size:16px !important;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) and (max-width:767px){
  .related-search-news{
    /*padding-left:5%;*/
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) and (max-width: 991px) {

}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199px) {

}

// Extra large devices (large desktops, 1200px and up)
@media (min-width:1200px){

}
/* Boostrap Breakpoints. END */

/*Custom Breakpoints*/
@media (min-width:827px){
  .all-item-container{
    min-width:760px !important;
    max-width:760px !important;
  }

  .search-input-group{
    min-width:745px !important;
    max-width:745px !important;
  }
}

@media (max-width:826px) and (min-width:768px){
  .all-item-container {
    min-width: 706px !important;
    max-width: 706px !important;

    .all-item-row{
      .with-image-column{
        .search-title-column{
          margin-left:40px;
        }

        .search-title{
          padding-left:24px;
        }

        .search-uri{
          padding-left:26px;
        }

        .search-description{
          padding-left:41px;
        }
      }
    }

  }

  .search-input-group{
    min-width:692px !important;
    max-width:692px !important;
  }
}

@media (min-width:768px){
  .news-item-container{
    min-width:650px !important;
    max-width:650px !important;
  }

  .result-info{
    min-width:650px !important;
    max-width:650px !important;
  }

  .did-you-mean{
    min-width:650px !important;
    max-width:650px !important;
  }
}

@media (max-width:767px){
  .all-item-container,.news-item-container{
    min-width:90% !important;
    max-width:100% !important;
  }

  .all-related-search-container{
    padding-left:12px !important;
    max-width: none;
    min-width: none;
    overflow: hidden;
  }

  .all-item-row{
    .with-image-column{
      .search-title-column{
        margin-top: 0px;
        margin-left: 14px;
      }

      .search-title{
        padding-left:0px;
      }

      .search-uri{
        padding-left:0px;
      }

      .search-description{
        padding-left:15px;
      }
    }
  }

  .search-input-group{
    min-width:90% !important;
    max-width:100% !important;
  }

  .result-info{
    min-width:90% !important;
    max-width:100% !important;

    /*> div{
      padding-left:6.8% !important;
    }*/
  }

  .did-you-mean{
    min-width:90% !important;
    max-width:100% !important;
  }

  .pagination-column{
    min-width:100% !important;
    max-width:100% !important;
  }
}

/*@media (min-width:970px){
  .search-input-group{
    min-width:870px !important;
    max-width:870px !important;
  }
}*/

.all-related-search-container{
  padding-left:0;
  max-width: 100%;
  min-width: 184px;
  overflow: hidden;
}

@media (max-width:1026px) and (min-width:768px){
  .all-related-search-container{
    padding-left:95px;
    max-width: none;
    min-width: none;
    overflow: hidden;
  }
}

@media (max-width: 1575px) {
  .contextual-web-text{
    font-size: 1.4rem;
  }
}
