@mixin search-console($device-name, $width, $input-width){
  $input-container-padding:null;
  $button-clear-margin-right:null;

  .console{
    border-radius: 6px;
    box-sizing: border-box;
    outline: none;
    height: 48px;
    display: flex;
    align-items: center;
    background-color: #fff !important;

    @if ($device-name == 'mobile'){
      $input-container-padding:0 0px 0 10px;
      $button-clear-margin-right:0;

      box-shadow: 0 0 0 1px rgba(0,0,0,.05), 0 1px 3px 1px rgba(0,0,0,.16);
      background-color: #fff;
      flex: 0 0 $width;
      z-index: 2;
    } @else{
      $input-container-padding:0 10px 0 19px;
      $button-clear-margin-right:8px;

      flex: 0 0 $width;
      border: 1px solid #ececec;

      &:hover, &.active{
        box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 2px 4px 1px rgba(0,0,0,.18);
        border-left: 1px solid transparent;
        border-right: none;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }
    }

    form{
      flex: 0 0 100%;
    }

    .input-container{
      position: relative;
      padding: $input-container-padding;
      display: flex;
      flex: 0 0 100%;

      .input-search{
        border: none;
        outline: none;
        border-radius: 6px;
        flex: 1 1;
      }

      .btn-clear{
        z-index: 100000;
        background: transparent;
        border: 0;
        margin-right: $button-clear-margin-right;
        width: 24px;
        flex: 0 0 20px;
        position: relative;
      }

      .btn-search{
        z-index: 100000;
        background-color: transparent;
        border: none;
        outline: none;
        flex: 0 0 35px;
      }
    }
  }
}

@mixin header($device-name, $orientation, $padding-top,$search-console-width,$input-width){
  .header{
    display: flex;
    flex-direction: row;
    padding-top: $padding-top;

    @if ($device-name == 'mobile'){
      flex-direction: column;
      margin-left: 8px;
      margin-right: 8px;

      .logo-mobile{
        display: flex;
        justify-content: center;

        img{
          width: 30px;
          height: auto;
          object-fit: cover;
          margin-top: 9px;
          margin-bottom: 9px;
        }
      }
    }

    $logo-width : 100px;
    $a-margin-right: 20px;
    $a-margin-left: 0;

    @if($device-name == 'tablet'){
      @if($orientation == 'landscape'){
        $logo-width : 50px;
        $a-margin-right : 12px;
        $a-margin-left : 40px;
      }
    }

    .logo{
      flex:0 0 $logo-width;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a{
        margin-right: $a-margin-right;
        margin-left: $a-margin-left;

        img{
          width: 30px;
          height: auto;
          object-fit: cover;
        }
      }
    }

    @include search-console($device-name,$search-console-width,$input-width);
  }
}

@mixin tabContainer($device-name){
  .tab-container{
    display: flex;
    border-bottom: 1px solid #ececec;

    @if ($device-name == 'mobile'){
      margin-top: 3px;
      .left-side{
        flex: 0 0 8px;
      }
    } @else if ($device-name == 'tablet') {
      margin-top: 16px;
      .left-side{
        flex: 0 0 30px;
      }
    } @else {
      margin-top: 13px;
      .left-side{
        flex: 0 0 100px;
      }
    }

    .custom-nav-tab{
      @if ($device-name == 'mobile'){
        height: 36px;
        margin-left: 12px;
      } @else {
        height: 39px;
      }

      li{
        font-size:11px;

        @if ($device-name == 'mobile'){
          padding-top: 12px;
          &:first-child{
            margin: 0 7px 0 0;
          }
          &:not(:first-child){
            margin: 0 7px;
          }
        } @else {
          padding: 3px 0;
          line-height: 30px;
          margin: 0 12px;
        }

        a{
          color: $text-color-primary-dark !important;

          @if ($device-name == 'mobile'){
            padding: 0 6px !important;
          } @else {
            padding: 0 8px !important;
          }

          &.active{
            font-weight: 600 !important;
          }
        }

        &:hover{
          &:not(.active) {
            border-bottom: 3px solid #ccc !important;
          }
        }
      }
    }
  }
}

@mixin mainContent ($device-name,$orientation){
  $min-height:calc(100vh - 173px);

  @if ($device-name == 'mobile'){
    @if ($orientation == 'landscape'){
      $min-height:calc(var(--windowInnerHeight) - 172px);
    } @else if ($orientation == 'portrait'){
      $min-height:calc(var(--windowInnerHeight) - 172px);
    }
  } @else if($device-name == 'tablet'){
    $min-height:calc(var(--windowInnerHeight) - 148px);
  }

  .content{
    display: flex;
    min-height: $min-height !important;
  }
}

@mixin itemsContainer($device-name,$orientation){
  .items-container{
    padding-right: 0 !important;
    padding-left: 0 !important;

    @if ($device-name == 'mobile'){
      margin-right: 5px !important;
      margin-left: 5px !important;

      .item-row-mobile{
        border-radius: 6px;
        -webkit-box-shadow: 0 1px 3px 1px rgba(0,0,0,.14);
        box-shadow: 0 1px 3px 1px rgba(0,0,0,.14);
        margin: 8px 0 !important;
        padding: 16px !important;
        display: flex;
        flex-direction: column;
        background-color: #fff !important;

        .title{
          font-size: 18px;
          line-height: 22px;
          font-weight: normal;
          color: #1a0dab !important;

          &:visited{
            color: #609 !important;
          }
        }

        .url{
          word-wrap: break-word;
          color: #006621 !important;
          padding-top: 1px;
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
          text-decoration: none;
          cursor: text;
          border-bottom: 1px solid #ddd;
          padding-bottom: 6px;
          margin-bottom: 6px;
        }

        .description{
          font-weight: normal;
          font-size: 14px;
          color: $text-color-primary-dark !important;
        }
      }

      .news-row-mobile{
        margin: 0 3px 10px 3px !important;
        padding: 16px !important;
        background-color: #fff;
        box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
        border-radius: 8px;
        display: flex;

        &:first-child{
          margin-top: 10px !important;
        }

        .description{
          flex: 1 1;
          display: flex;
          flex-direction: column;
          padding-right: 16px;

          .domain{
            display: flex;
            align-items: center;
            margin-bottom: 8px !important;

            img{
              width: auto;
              height: 16px;
              margin-right: 5px;
            }
          }

          .title{
            font-size: 16px;
            line-height: 20px;
            color: #202124;
            margin-bottom: 8px;
          }

          .time{
            font-size: 11px;
            color: #5f6368;
            line-height: 16px;
          }
        }

        .image{
          flex: 0 0 92px;

          .news-image{
            width:100%;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }
    } @else {
      margin-right: 0 !important;
      margin-left: 0 !important;

      @if ($device-name == 'tablet' and $orientation == 'landscape'){
        min-width: 557px !important;
      } @else {
        min-width: 648px !important;
      }

      .item-row{
        display: flex;
        padding: 12px 20px 0;
        margin: 0 0 2px;

        &:first-child{
          padding-top: 10px;
        }

        .image-column{
          flex: 13% 0 0;
        }

        .content-column{
          display: flex;
          flex-direction: column;

          &.with-image{
            flex: 87% 0 0;
          }

          &.without-image{
            flex: 100% 0 0;
          }

          .title{
            font-size: 20px;
            line-height: 24px;
            color: #1a0dab !important;

            &:visited{
              color: #609 !important;
            }
          }

          .url{
            word-wrap: break-word;
            color: #006621 !important;
            padding-top: 1px;
            padding-bottom: 0;
            line-height: 24px;
            font-size: 16px;
            text-decoration: none;
            cursor: text;
            -webkit-user-select: text;
            -moz-user-select: text;
            -ms-user-select: text;
            user-select: text;
          }

          .description{
            line-height: 20px;
            color: $text-color-primary;
            font-size: 13px;
            padding-bottom: 10px !important;
          }
        }
      }

      .news-row{
        padding: 14px 16px !important;
        background-color: #fff;
        display: flex;
        border: 1px solid #DADCE0;
        border-radius: 8px;
        margin-bottom: 16px;
        cursor: pointer;
        text-decoration: none;

        &:visited{
          .title{
            color: #70757a !important;
          }
        }

        &:hover{
          text-decoration: none;

          .title{
            color: #1a73e8 !important;
          }
        }

        &:first-child{
          margin-top: 10px !important;
        }

        &:last-child{
          margin-bottom: 0 !important;
        }

        .text-content{
          flex: 1 1;
          display: flex;
          flex-direction: column;
          padding-right: 16px;

          .domain{
            display: flex;
            align-items: center;
            height: 16px !important;
            margin-bottom: 8px !important;

            color: rgba(60, 64, 67, 1.0);
            font-size: 12px;
            line-height: 16px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            img{
              width: 16px;
              height: 16px;
              object-fit: cover;
              margin-right: 8px;
            }
          }

          .title{
            font-size: 18px;
            line-height: 1.3333333333333333em;
            color: #202124;
            margin-bottom: 8px;
          }

          .description{
            color: #70757a;
            font-size: 14px;
            line-height: 1.4285714285714286em;
            max-height: 4.285714285714286em;
            overflow: hidden;
          }

          .time{
            font-size: 11px;
            color: #5f6368;
            line-height: 16px;
            padding-top: 4px;
          }
        }

        .image{
          flex: 0 0 112px;

          .news-image{
            width:100%;
            height: 112px;
            object-fit: cover;
            border-radius: 8px;
          }
        }
      }
    }
  }
}

@mixin relatedSearch($device-name,$orientation){
  @if ($device-name == 'mobile'){
    border-radius: 6px;
    -webkit-box-shadow: 0 1px 3px 1px rgba(0,0,0,.14);
    box-shadow: 0 1px 3px 1px rgba(0,0,0,.14);
    background-color: #fff !important;
    margin: 8px 5px !important;
    padding: 16px 0 0 0 !important;

    h2{
      padding: 0 10px 16px 16px;
      font-size: 14px;
      line-height: 20px;
      color: $text-color-primary-dark;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      li{
        border-top: 1px solid #ececec;
        line-height: 20px;
        padding: 12px 16px;

        a{
          font-size: 14px;
          font-weight: normal;
        }

        .search-icon{
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAJ1BMVEUAAAB1dXVwcHB3d3d1dXV2dnZ2dnZ1dXV2dnZ2dnZ1dXV3d3d2dnZM0kCXAAAADHRSTlMAsBAggFDQMPCgPZADTT2XAAAAWUlEQVR42m3P1QHDUAxDUfkx3P3nLaiY5HyarbsaQFTJUmCRHAgoWblA6KFC10OH6oIiKy6BLMvggN7gVHGe8btl4S2anztG+r900Bx5/5KaI1+XkdB/JPYNByIDjXDNsx4AAAAASUVORK5CYII=);
          width: 16px;
          height: 16px;
          margin-top: 2px;
        }
      }
    }
  } @else if $device-name == 'tablet' and $orientation == 'portrait' {
    width: 648px;
    margin-left: 56px;

    h2{
      padding: 0 10px 16px 0;
      font-size: 14px;
      line-height: 20px;
      color: $text-color-primary-dark;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
    }

    ul{
      list-style: none;
      padding: 0;
      margin: 0;

      li{
        border-top: 1px solid #ececec;
        line-height: 20px;
        padding: 12px 12px 12px 0;

        a{
          font-size: 14px;
          font-weight: normal;
        }

        .search-icon{
          background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAJ1BMVEUAAAB1dXVwcHB3d3d1dXV2dnZ2dnZ1dXV2dnZ2dnZ1dXV3d3d2dnZM0kCXAAAADHRSTlMAsBAggFDQMPCgPZADTT2XAAAAWUlEQVR42m3P1QHDUAxDUfkx3P3nLaiY5HyarbsaQFTJUmCRHAgoWblA6KFC10OH6oIiKy6BLMvggN7gVHGe8btl4S2anztG+r900Bx5/5KaI1+XkdB/JPYNByIDjXDNsx4AAAAASUVORK5CYII=);
          width: 16px;
          height: 16px;
          margin-top: 2px;
        }
      }
    }
  } @else{
    $width: 432px;

    @if $device-name == 'tablet' and $orientation == 'landscape'{
      $width: 200px;
    }

    flex: 0 0 $width;
    padding: 0 20px;
    margin: 52px 0 0 40px;

    h2{
      font-size: 20px;
      font-weight: normal;
      line-height: 24px;
      padding-bottom: 11px;
      margin-bottom: 0;
    }

    ul{
      border: 0;
      border-collapse: collapse;
      border-spacing: 0;
      list-style: none;
      padding: 0;

      li{
        &:not(:last-child){
          padding: 0 0 10px 0;
        }

        border: 0;
        border-collapse: collapse;
        border-spacing: 0;
        list-style: none;
        margin: 0;
        font-size: 13px;
        line-height: 1.2em;
      }
    }
  }
}

@mixin leftSideBlank($device-name){
  $width: 100px;

  @if ($device-name == 'tablet') {
    $width: 35px;
  }

  .left-side-blank{
    flex: 0 0 $width;
    height: 200px !important;
  }
}

@mixin paginationContainer($device-name){
  @if ($device-name == 'mobile'){
    padding: 0 10px;
    margin: 10px 5px;
    border-radius: 6px;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,.14);
    background-color: #fff;

    .pagination{
      justify-content: center;

      .previous-item{
        margin-right: 8px !important;

        img{
          transform-origin: 484px 24px;
        }
      }

      .next-item{
        margin-right: 0 !important;
      }

      .page-item{
        a.page-link {
          margin-right: 8px;
          font-size: 14px !important;
          font-weight: 600 !important;

          .first-label{
            color: #444;
            font-size: 14px;
            font-weight: 400;
            line-height: 44px;
            padding-top: 2px;
          }

          .page-label{
            padding-top: 6px !important;
          }
        }
      }
    }
  } @else {
    padding: 26px 0 26px 20px;
    margin: 0 0 2px 100px;

    .pagination {
      .previous-item {
        img{
          transform-origin: 484px 25px;
        }
      }
    }
  }
}

@mixin slimScrollbarMDTable(){
  $track-color: #f1f1f1;
  $thumb-color: #c1c1c1;
  $thumb-hover-color: #a4a4a4;

  //Custom Scrollbar for webkit
  ::-webkit-scrollbar {
    width: 5px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $track-color;
    border-radius: 20px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $thumb-color;
    border-radius: 20px;
    height: 30px !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $thumb-hover-color;
  }

  //Custom Scrollbar for moz
  .MuiPaper-root{
    scrollbar-color: $thumb-color #f1f1f1  !important;
    scrollbar-width: thin !important;
  }

  .MuiTableContainer-root{
    scrollbar-color: $thumb-color #f1f1f1  !important;
    scrollbar-width: thin !important;
  }
}
